<template>
  <div id="theWholeCard" v-if="rental.approved">
    
  <div :id="'c' + rental.id" class="carousel carousel-dark slide" data-bs-pause="false">
  <div class="carousel-inner">
    <div  class="carousel-item imagePart" v-for="image in rental.bunnyImageUrls" :key="image" :class="image === rental.bunnyImageUrls[0] ? 'active' : ''">
      <!-- <div class="carousel-indicators">
        <p style="color: white">{{rental.imageUrls.indexOf(image)}} / {{rental.imageUrls.length}}</p>
      </div>   -->
      <router-link :to="rentalDetail" class="imageContainer">
        <img :src="image" class="img-fluid rounded d-block" :alt="rental.id"/>
        <p class="top-right">{{1 + rental.bunnyImageUrls.indexOf(image)}} / {{rental.bunnyImageUrls.length}}</p>
      </router-link>
        
    </div>
  </div>
  <button class="carousel-control-prev" type="button" :data-bs-target="'#c' + rental.id" data-bs-slide="prev">
    <span class="carousel-control-prev-icon bclour" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" @click="thisButton" type="button" :data-bs-target="'#c' + rental.id" data-bs-slide="next">
    <span class="carousel-control-next-icon bclour" aria-hidden="true"></span>
    <span class="visually-hidden ">Next</span>
  </button>
</div>


    <div class="px-4">
        <p class="card-title m-0">
         <strong class="fst-itali">{{ rental.location }}, Kampala</strong>
        </p>
        <p class="card-text m-0">
          <span class="">{{ rental.numberOfBedrooms }} <span v-if="rental.numberOfBedrooms == 1">Bedroom</span><span v-else> Bedrooms</span> <span v-if="rental.sittingRoom > 0">and {{ rental.sittingRoom }} sitting room</span></span>
        </p>
        <p class="m-0"> 
          <strong>{{ rental.price.toLocaleString('UG', { style: 'currency', currency: 'UGX' }) }} </strong> / month
        </p>
        <!-- <p>{{ rental.description }}</p> -->
        <p>
          Contact us on 
            <i class="fa-brands fa-whatsapp"></i> <a href="https://wa.me/+256751568755/?text=Hello">+256 751568755</a>
            <!-- <br><a href="tel: +256788380658">+256 788380658</a> -->
        </p>
        <div class="row justify-content-between">
        <router-link :to="rentalLink" class="btn btn-outline-info col-4"
          >Send a request</router-link
        >
        <button
          type="button"
          class="btn btn-outline-info col-4"
          data-bs-toggle="modal"
          :data-bs-target="'#b' + rental.videoId"
          @click="toggleRentalVideo"
          v-if="rental.videoId"
        >
          Watch video
        </button>
        </div>
  
        <!-- Modal -->
        <teleport to="body">
          <div
            class="modal fade myModal"
            :id="'b' +rental.videoId"
            tabindex="-1"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-scrollable modal-fullscreen">
              <div class="modal-content">
                <div class="modal-header p-0 myHeader">
                  <h5 class="modal-title" id="exampleModalLabel">Video</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    @click="rentalVideo = false"
                  ></button>
                </div>
                <div class="modal-body m-0 increaseHieght">
                  <div class="row" v-if="rentalVideo">
                    <iframe
                      class="col"
                      :src="'https://www.youtube.com/embed/' + rental.videoId"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </teleport>
      </div>
  </div>
</template>

<script>
export default {
  props: ["rental"],
  data() {
    return {
      rentalVideo: false,
    };
  },
  computed: {
    rentalLink() {
      return "/rentals/" + this.rental.id + '/contact';
    },
    rentalDetail() {
      return "/rentals/" + this.rental.id;
    },
  },
  methods: {
    thisButton() {
      // console.log('The images', this.rental.bunnyImageUrls[0])
    },
    toggleRentalVideo() {
      this.rentalVideo = !this.rentalVideo;
      // var iframes = document.querySelectorAll("iframe");
      // Array.prototype.forEach.call(iframes, (iframe) => {
      //   iframe.contentWindow.postMessage(
      //     JSON.stringify({ event: "command", func: "stopVideo" }),
      //     "*"
      //   );
      // });
    },
  },
  created() {
    // console.log("Whenn created", this.rental.videoId)
  }
};
</script>

<style scoped>
#theWholeCard {
  max-height: 100vh;
  width: 25rem;
  max-width: 25rem;
}

div .imagePart {
  width: 100%;
  height: 17rem;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageContainer {
  position: relative;
  text-align: center;
  color: white;
}

.top-right {
  position: absolute;
  top: 1rem;
  right: 10rem;
  background-color: rgb(65, 59, 59);
  padding-left: .3rem;
  padding-right: .5rem;
  border-radius: .2rem;
}

a {
  text-decoration: none;
}

.bclour {
  background-color: black;
  border-radius: .3rem;
}

.increaseHieght {
  height: 90vh;
  top: 0vh;
  width: 100%;
}
.myHeader {
  margin-bottom: 0;
  margin-right: 1rem;
}

iframe {
  height: 33rem;
  width: 100%;
}

.myModal {
  width: 100%;
}

</style>