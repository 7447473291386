<template>
  <div>
    <section>
      <div id="filter">
        <button
          type="button"
          class="btn btn-outline-secondary dropdown-toggle mt-3"
          data-bs-toggle="modal"
          data-bs-target="#filterModal"
          @click="toggleFilter"
        >
          Filters
        </button>
      </div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="filterModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Filter By</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <rental-filter @search="searchCriteria"></rental-filter>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="filteredRentals.length >= 1">
      <div class="container">
        <div class="row justify-content-around">
          <div v-if="isLoading">
            <base-spinner></base-spinner>
          </div>
          <rental-item
            class="col-sm-5 col-md-4 mt-3 m-1"
            v-for="rental in filteredRentals"
            :key="rental.id"
            :rental="rental"
          ></rental-item>
        </div>
      </div>
    </section>

    <!-- <section v-for="houseType in houseTypeRentals" :key="houseType">
      <div class="card">
        <div>
          <p class="fs-4 fw-bold" v-if="houseType[0] != 'undefined'">
            {{ houseType[0] }}
            <span
              ><router-link class="ms-2 fs-6" to="/rentals"
                >See all <i class="bi bi-arrow-right"></i></router-link
            ></span>
          </p>
        </div>

        <vue-horizontal>
          <home-rental-item
            class="col-sm-5 col-md-4 mt-1 m-3"
            v-for="rental in houseType[1]"
            :key="rental.id"
            :rental="rental"
          ></home-rental-item>
        </vue-horizontal>
      </div>
    </section> -->
  </div>
</template>

<script>
import RentalItem from "../../components/rentals/rentalItem.vue";
// import HomeRentalItem from "../../components/rentals/homeRentalItem.vue";
import RentalFilter from "../../components/rentals/RentalFilter.vue";
// import VueHorizontal from "vue-horizontal";

export default {
  components: {
    RentalFilter,
    RentalItem,
    // HomeRentalItem,
    // VueHorizontal
  },
  data() {
    return {
      showFilter: false,
      isLoading: false,
      location: "All",
      // country: "",
      price: 100000000,
      houseType: "All",
      locations: [
        "Bakuli",
        "Bugoloobi",
        "Bukoto",
        "Busega",
        "Butabika",
        "Butikiro",
        "Bwaise",
        "Ggaba",
        "Kabalagala",
        "Kaleerwa",
        "Kampala Hill",
        "Kasanga",
        "Kanyanya",
        "Kasubi hill",
        "Katwe",
        "Kawaala",
        "Kawempe",
        "Kibuli",
        "Kibuye, Uganda",
        "Kigoowa",
        "Kikaaya",
        "Kisaasi",
        "Kololo",
        "Kulambiro",
        "Kyebando",
        "Kisenyi",
        "Lubaga",
        "Lugogo, Kampala",
        "Lungujja",
        "Luzira",
        "Makerere",
        "Makerere Kikoni",
        "Makindye",
        "Mbuya",
        "Mengo",
        "Mpererwe",
        "Mulago",
        "Munyonyo",
        "Muyenga",
        "Naakulabye",
        "Nakasero",
        "Nakawa",
        "Namirembe Hill",
        "Namungoona",
        "Namuwongo",
        "Nateete",
        "Ndeeba",
        "Nsambya",
        "Ntinda",
        "Old Kampala",
        "Port Bell",
        "Wandegeya",
      ],
    };
  },
  computed: {
    // rentals() {
    //   return this.$store.getters["rentals/properties"];
    // },
    filteredRentals() {
      const rentals = this.$store.getters["rentals/properties"];
      return rentals.filter((rental) => {
        if (
          this.location === "All" &&
          rental.price <= this.price &&
          !this.isLoading &&
          this.houseType === "All" &&
          // rental.country == this.country &&
          rental.approved &&
          !rental.booked
        ) {
          // if (rental.country == this.country) {
          //     console.log('True')
          //   }
          // console.log('inside inside', this.country, 'and inside the rental', rental.country)
          return true;
        }

        for (let i = 0; i < this.locations.length; i++) {
          if (
            !this.isLoading &&
            this.location === "All" &&
            rental.price <= this.price &&
            this.houseType === rental.houseType &&
            // rental.country == this.country &&
            rental.approved &&
            !rental.booked
          ) {
            // if (rental.country == 'Uganda') {
            //   console.log('True')
            // }
            // console.log('inside inside', this.country, 'and inside the rental', rental.country)
            return true;
          }
          if (
            !this.isLoading &&
            this.location === this.locations[i] &&
            rental.location === this.locations[i] &&
            rental.price <= this.price &&
            this.houseType === "All" &&
            // rental.country == this.country &&
            rental.approved &&
            !rental.booked
          ) {
            return true;
          }
          if (
            !this.isLoading &&
            this.location === this.locations[i] &&
            rental.location === this.locations[i] &&
            rental.price <= this.price &&
            this.houseType === rental.houseType &&
            // rental.country == this.country &&
            rental.approved &&
            !rental.booked
          ) {
            return true;
          }
        }
        return false;
      });
    },
    houseTypeRentals() {
      const rentals = this.$store.getters["rentals/properties"];
      const groupByHouseType = rentals.reduce((group, rental) => {
        const { houseType } = rental;
        group[houseType] = group[houseType] ?? [];
        group[houseType].push(rental);
        return group;
      }, {});

      // console.log('When grouped',groupByHouseType);
      // return groupByHouseType
      // let types = Object.keys(groupByHouseType);
      let types = groupByHouseType;
      // console.log('When turned into an array', Object.entries(types))
      return Object.entries(types);
    },
  },
  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    async loadRentals() {
      this.isLoading = true;
      await this.$store.dispatch("rentals/loadRentals");
      this.isLoading = false;
    },
    searchCriteria(val) {
      this.location = val.location;
      (this.price = val.price), (this.houseType = val.houseType);
    },
  },
  async created() {
    // const location = await fetch("http://ip-api.com/json/");
    // const locationData = await location.json();
    // this.country = locationData.country;
    // console.log('the country', this.country)
  },
  // updated() {
  //   this.loadRentals();
  // }
};
</script>

<style scoped>
#filter {
  text-align: center;
  margin-bottom: 0.3rem;
}
</style>